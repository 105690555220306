import * as React from 'react'
import { ViewState, EditingState, Resources } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu
} from '@devexpress/dx-react-scheduler-material-ui'
import { Add } from '@material-ui/icons'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardAttendanceSlice, coreSlice, dashboardPatientSlice, dashboardAgendaSlice, componentsSelectPatientSlice, componentsSelectDoctorSlice, componentsSelectSupervisorSlice } from '../../../reducers'
import SelectPatient from '../../select-patient'
import { PagesContext } from '../../../contexts/pages'
import SelectDoctor from '../../select-doctor'
import { useMediaQuery } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import CreateEditEvent from './create-edit-event'
import CreateEditMeeting from './create-edit-meeting'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmModal from '../../confirm-modal'
import { initialState } from '../../../reducers/dashboard/agenda/slice'
import { blue, green, blueGrey, purple } from '@mui/material/colors'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SelectSupervisor from '../../select-supervisor'
import CreateEditMentoring from './create-edit-mentoring'
import CreateEditAvailability from './create-edit-availability'
import selectSupervisor from '../../../reducers/components/select-supervisor'

const appointmentFormMessages = {
  afterLabel: 'Até',
  allDayLabel: 'Dia todo',
  commitCommand: 'Salvar',
  daily: 'Diariamente',
  daysLabel: 'dias',
  detailsLabel: 'Detalhes do atendimento',
  endRepeatLabel: 'Repetir até',
  everyLabel: 'everyLabel',
  firstLabel: 'firstLabel',
  fourthLabel: 'fourthLabel',
  lastLabel: 'lastLabel',
  monthly: 'Mensalmante',
  monthsLabel: 'monthsLabel',
  moreInformationLabel: 'Mais informações',
  never: 'Nunca',
  notesLabel: 'Mais informações',
  occurrencesLabel: 'vezes',
  ofEveryMonthLabel: 'ofEveryMonthLabel',
  ofLabel: 'ofLabel',
  onLabel: '',
  repeatEveryLabel: 'Repetir a cada',
  repeatLabel: 'Recorrente',
  secondLabel: 'secondLabel',
  theLabel: 'theLabel',
  thirdLabel: 'thirdLabel',
  titleLabel: 'Título',
  weekly: 'Semanalmente',
  weeksOnLabel: 'weeksOnLabel',
  yearly: 'Anualmente',
  yearsLabel: 'yearsLabel'
}

const DayScaleCell = ({ startDate, today }) => {
  const width = React.useRef(window.innerWidth)
  const dateString = moment(startDate)
    .locale('pt-br')
    .format('dddd')
    .toUpperCase()
  const date = width?.current >= 1000 ? dateString : dateString.slice(0, 3)
  return <td className='text-white text-center w-[15%] py-5'>{date}</td>
}

const DayScaleComponent = ({ children, ...props }) => {
  return (
    <tr className='inline-flex w-full justify-between bg-primary-blue'>
      {children}
    </tr>
  )
}

let closeToolTip = null
export default function Agenda() {
  const {
    dashBoardPageInfo,
    setDashBoardPageInfo
  } = React.useContext(PagesContext)
  const [searchBy, setSearchBy] = React.useState('doctor')
  const agenda = useSelector(dashboardAgendaSlice.selectors.selectAgenda)
  const meetings = useSelector(dashboardAgendaSlice.selectors.selectMeetings)
  const availability = useSelector(dashboardAgendaSlice.selectors.selectAvailability)
  const availabilityItem = useSelector(dashboardAgendaSlice.selectors.selectAvailabilityItem)
  const user = useSelector(coreSlice.selectors.selectUser)
  const patient = useSelector(coreSlice.selectors.selectPatient)
  const pagePatient = useSelector(dashboardAgendaSlice.selectors.selectPatient)
  const selectedDoctor = useSelector(componentsSelectDoctorSlice.selectors.selectDoctor)
  const selectedPatient = useSelector(componentsSelectPatientSlice.selectors.selectPatient)
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [month, setMonth] = React.useState(new Date().getMonth())
  const [data, setData] = React.useState([])
  const dispatcher = useDispatch()
  const isDoctor = React.useMemo(() => user?.role === 'doctor', [user.role])
  const isOwner = React.useMemo(() => user?.role === 'owner', [user.role])
  const isAdmin = React.useMemo(() => user?.role === 'admin' || user?.role === 'owner', [user.role])
  const isPatientOrFamily = React.useMemo(() => user?.role === 'patient' || user?.role === 'family', [user.role])
  const isNotAdmin = React.useMemo(() => isDoctor || isOwner || isPatientOrFamily, [isDoctor, isPatientOrFamily, isOwner])
  const roleInfo = React.useMemo(() => (isDoctor || ((isAdmin || isOwner) && searchBy === 'doctor')) ? 'patient' : 'doctor', [isAdmin, isDoctor, searchBy])
  const [editEvent, setEditEvent] = React.useState(false)
  const [editMentoring, setEditMentoring] = React.useState(false)
  const [createEvent, setCreateEvent] = React.useState(false)
  const [createMeeting, setCreateMeeting] = React.useState(false)
  const [createMentoring, setCreateMentoring] = React.useState(false)
  const [createAvailability, setCreateAvailability] = React.useState(false)
  const [editAvailability, setEditAvailability] = React.useState(false)
  const [editMeeting, setEditMeeting] = React.useState(false)
  const [deleteEvent, setDeleteEvent] = React.useState(false)
  const [deleteMeeting, setDeleteMeeting] = React.useState(false)
  const [deleteMentoring, setDeleteMentoring] = React.useState(false)
  const [deleteAvailabilityItem, setDeleteAvailabilityItem] = React.useState(false)
  const [createEventFromSinglePatientPage, setCreateEventFromSinglePatientPage] = React.useState(false)
  const event = useSelector(dashboardAgendaSlice.selectors.selectEvent)
  const meeting = useSelector(dashboardAgendaSlice.selectors.selectMeeting)
  const mentoring = useSelector(dashboardAgendaSlice.selectors.selectMentoring)
  const supervisor = useSelector(componentsSelectSupervisorSlice.selectors.selectSupervisor)
  const patientFromSinglePatientPage = useSelector(dashboardPatientSlice.selectors.selectPatient)
  const [getAgenda, getMeetings, getAvailability] = useEvent([dashboardAgendaSlice.eventBus.getAgenda, dashboardAgendaSlice.eventBus.getMeetings, dashboardAgendaSlice.eventBus.getAvailability])

  React.useEffect(() => {
    dispatcher(componentsSelectPatientSlice.actions.setPatient({}))
    dispatcher(componentsSelectDoctorSlice.actions.setDoctor({}))
    dispatcher(dashboardAgendaSlice.actions.setAgenda([]))
    dispatcher(dashboardAgendaSlice.actions.setMeetings([]))
    dispatcher(dashboardAgendaSlice.actions.setAvailability([]))
  }, [dispatcher, searchBy])

  const navigate = useNavigate()
  const { tenantKey } = useParams()
  const isSupervisor = React.useMemo(() => user?.isSupervisor, [user])

  const fetchForAdmin = React.useCallback(() => {
    const pk = searchBy === 'doctor' ? selectedDoctor?.pk : searchBy === 'patient' ? selectedPatient?.pk : null
    if (pk) {
      getAgenda.trigger({ pk, year, month, searchBy })
    }
  }, [month, searchBy, selectedDoctor, selectedPatient, year])

  const isAnotherUserData = React.useMemo(() => isAdmin && (selectedDoctor?.pk || selectedPatient?.pk) && (selectedDoctor?.pk !== user?.pk || selectedPatient?.pk !== user?.pk), [selectedDoctor, selectedPatient, user, isAdmin])

  const fetchAgenda = React.useCallback(() => {
    if (user.role === 'admin' || user.role === 'owner') {
      fetchForAdmin()
    } else if (user.role === 'doctor') {
      getAgenda.trigger({ pk: user.pk, year, month })
    } else if ((user.role === 'family' || user.role === 'patient') && patient?.pk) {
      getAgenda.trigger({ pk: patient.pk, year, month })
    }
  }, [fetchForAdmin, month, patient, user, year])

  const fetchMeetings = React.useCallback(() => {
    getMeetings.trigger({ year, month })
  }, [month, year])

  const fetchMentoring = React.useCallback(() => {
    if (supervisor && supervisor.pk) {
      getAvailability.trigger({ supervisor: supervisor.pk, month, year })
    } else {
      dispatcher(dashboardAgendaSlice.actions.setAvailability([]))
    }
  }, [supervisor, month, year, dispatcher])

  const [
    removeEventFromAgenda,
    removeMeeting,
    removeAvailability
  ] = useEvent([
    { ...dashboardAgendaSlice.eventBus.removeEventFromAgenda, onSuccess: fetchAgenda },
    {
      ...dashboardAgendaSlice.eventBus.deleteMeeting, onSuccess: () => {
        fetchMeetings();
        fetchMentoring()
      }
    },
    { ...dashboardAgendaSlice.eventBus.removeAvailability, onSuccess: fetchMentoring }
  ])

  const setEvent = React.useCallback((eventInfo) => {
    if (eventInfo) {
      const { startDate, endDate } = eventInfo
      var info = {
        ...eventInfo,
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf()
      }
    } else {
      var info = null
    }
    dispatcher(dashboardAgendaSlice.actions.setEvent(info))
  }, [dispatcher])

  const setMeeting = React.useCallback((meetingInfo) => {
    if (meetingInfo) {
      const { startDate, endDate } = meetingInfo
      var info = {
        ...meetingInfo,
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf()
      }
    } else {
      var info = null
    }
    dispatcher(dashboardAgendaSlice.actions.setMeeting(info))
  }, [dispatcher])

  const setMentoring = React.useCallback((mentoringInfo) => {
    if (mentoringInfo) {
      const { startDate, endDate } = mentoringInfo
      var info = {
        ...mentoringInfo,
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf()
      }
    } else {
      var info = null
    }
    dispatcher(dashboardAgendaSlice.actions.setMentoring(info))
  }, [dispatcher])

  const setSupervisor = React.useCallback((supervisorInfo) => {
    dispatcher(selectSupervisor.actions.setSupervisor(supervisorInfo))
  }, [dispatcher])

  const setAvailabilityItem = React.useCallback((availabilityInfo) => {
    if (availabilityInfo) {
      const { startDate, endDate } = availabilityInfo
      var info = {
        ...availabilityInfo,
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf()
      }
    } else {
      var info = null
    }
    dispatcher(dashboardAgendaSlice.actions.setAvailabilityItem(info))
  }, [dispatcher])

  const handleEditEvent = () => {
    closeToolTip && closeToolTip()
    setEditEvent(true)
    dispatcher(componentsSelectPatientSlice.actions.setPatient(pagePatient))
  }

  const handleEditMeeting = () => {
    closeToolTip && closeToolTip()
    setEditMeeting(true)
  }

  const handleEditAvailability = () => {
    closeToolTip && closeToolTip()
    setEditAvailability(true)
  }

  const handleEditMentoring = () => {
    closeToolTip && closeToolTip()
    setEditMentoring(true)
  }

  const handleCreateEvent = React.useCallback(() => {
    setEvent(initialState.event)
    setCreateEvent(true)
  }, [setEvent])

  const handleCreateMeeting = React.useCallback(() => {
    setMeeting(initialState.meeting)
    setCreateMeeting(true)
  }, [setCreateMeeting])

  const handleCreateMentoring = React.useCallback(() => {
    setMentoring(initialState.mentoring)
    setCreateMentoring(true)
  }, [setCreateMentoring])

  const handleCreateAvailabilityItem = React.useCallback(() => {
    setAvailabilityItem(initialState.availabilityItem)
    setCreateAvailability(true)
  }, [setCreateMentoring])

  const onCancelEventUpdate = () => {
    setEditEvent(false)
  }

  const onCancelMeetingUpdate = () => {
    setEditMeeting(false)
  }

  const onCancelMentoringUpdate = () => {
    setEditMentoring(false)
  }

  const onCancelEventCreation = () => {
    setCreateEvent(false)
  }

  const onCancelMeetingCreation = () => {
    setCreateMeeting(false)
  }

  const onCancelMentoringCreation = () => {
    setCreateMentoring(false)
  }

  const onCancelAvailabilityCreation = () => {
    setCreateAvailability(false)
  }

  const onCancelAvailabilityUpdate = () => {
    setEditAvailability(false)
  }

  const handleDeleteEvent = () => {
    closeToolTip && closeToolTip()
    setDeleteEvent(true)
  }

  const handleDeleteAvailabilityItem = () => {
    closeToolTip && closeToolTip()
    setDeleteAvailabilityItem(true)
  }

  const handleDeleteMeeting = () => {
    closeToolTip && closeToolTip()
    setDeleteMeeting(true)
  }

  const handleDeleteMentoring = () => {
    closeToolTip && closeToolTip()
    setDeleteMentoring(true)
  }

  const confirmDeleteEvent = React.useCallback(() => {
    removeEventFromAgenda.trigger({ startDate: event.startDate })
    setDeleteEvent(false)
  }, [event])

  const confirmDeleteMeeting = React.useCallback(() => {
    removeMeeting.trigger({ sk: meeting?.sk })
    setDeleteMeeting(false)
  }, [meeting])

  const confirmDeleteMentoring = React.useCallback(() => {
    removeMeeting.trigger({ sk: mentoring?.sk })
    setDeleteMentoring(false)
  }, [mentoring])

  const confirmDeleteAvailabilityItem = React.useCallback(() => {
    removeAvailability.trigger({ startDate: availabilityItem?.startDate })
    setDeleteAvailabilityItem(false)
  }, [availabilityItem])

  React.useEffect(() => {
    fetchAgenda()
    fetchMeetings()
  }, [fetchAgenda, fetchMeetings])

  React.useEffect(() => {
    fetchMentoring()
  }, [supervisor, month, year])

  React.useEffect(() => {
    if (isSupervisor) {
      setSupervisor(user)
    }
  }, [isSupervisor, user])

  React.useEffect(() => {
    if (dashBoardPageInfo.autoOpenNewEvent) {
      setCreateEventFromSinglePatientPage(true)
      setEvent(initialState.event)
      dispatcher(componentsSelectPatientSlice.actions.setPatient(patientFromSinglePatientPage))
      setDashBoardPageInfo((state) => ({ ...state, autoOpenNewEvent: false }))
    }
  }, [dashBoardPageInfo, dashBoardPageInfo.autoOpenNewEvent, dispatcher, handleCreateEvent, patientFromSinglePatientPage, setDashBoardPageInfo, setEvent])

  const AppointmentTooltipContent = ({
    appointmentData,
    ...restProps
  }) => {
    const {
      setDashBoardPage,
      setSinglePatientPage,
      SinglePatientPages,
      DashBoardPages
    } = React.useContext(PagesContext)
    const beginAppointment = () => {
      if (isDoctor) {
        dispatcher(dashboardPatientSlice.actions.setPatient(appointmentData[roleInfo]))
        dispatcher(dashboardAttendanceSlice.actions.setPatient(appointmentData[roleInfo]))
        setDashBoardPage(DashBoardPages.PATIENTS)
        setSinglePatientPage(SinglePatientPages.PATIENT_ATTENDANCE)
      } else {
        setDashBoardPage(DashBoardPages.ATTENDANCE)
      }
    }

    React.useEffect(() => {
      if (isPatientOrFamily) {
        setPagePatient(patient)
      } else if (roleInfo === 'patient') {
        setPagePatient(appointmentData[roleInfo])
      } else if (isAdmin) {
        setPagePatient(selectedPatient)
      }
    }, [appointmentData])

    const { location: { origin } } = window

    return (
      <AppointmentTooltip.Content
        appointmentData={appointmentData}
        {...restProps}
        children={
          <>
            {restProps.children}
            {event ? (isNotAdmin &&
              <div className='flex justify-end'>
                <button onClick={beginAppointment} className='btn-primary'>
                  {isDoctor ? 'Iniciar Atendimento' : 'Assistir Atendimento'}
                </button>
              </div>) : (meeting || mentoring) ? (
                <div className='flex justify-end'>
                  <button onClick={() => {
                    navigator.clipboard.writeText(`${origin}/${tenantKey}/meeting/${(meeting || mentoring)?.sk}`)
                    toast.success('O link para a reunião foi copiado.')
                  }} className='btn-primary'>
                    Copiar link
                    <ContentCopyIcon sx={{ fontSize: 14 }} />
                  </button>
                  <button onClick={() => { navigate(`/${tenantKey}/meeting/${(meeting || mentoring)?.sk}`) }} className='btn-primary'>
                    {isAbleToEdit ? 'Iniciar' : 'Participar'}
                  </button>
                </div>) : <></>
            }
          </>
        }
      />
    )
  }

  const CellBase = React.memo(
    ({ startDate, formatDate, otherMonth, today }) => {
      const isFirstMonthDay = startDate.getDate() === 1
      const formatOptions = isFirstMonthDay
        ? {
          day: 'numeric',
          month: 'long'
        }
        : { day: 'numeric' }
      const handleDoubleClick = () => {
        handleCreateEvent()
        setEvent({ startDate: startDate?.valueOf(), endDate: startDate?.valueOf() + (3600) })
      }
      return (
        <td
          className={`${today ? 'bg-light-gray' : ''}${otherMonth ? 'opacity-50' : ''} align-top h-24 border-2`}
          onDoubleClick={isDoctor ? handleDoubleClick : null}
        >
          <div className='p-2'>{formatDate(startDate, formatOptions)}</div>
        </td>
      )
    }
  )

  React.useEffect(() => {
    setData([...availability.map(item => ({ type: 'availability', title: `Disponibilidade de ${supervisor?.name}`, ...item })), ...agenda.map(item => ({ type: 'agenda', ...item })), ...(isAnotherUserData ? [] : meetings.map(meeting => ({ type: 'meeting', ...meeting })))].map((event) => ({
      id: event.startDate, ...event, defaultTitle: event.title, title: `${event.title} das ${moment(event.startDate).format('HH:mm')} às ${moment(
        event.endDate
      ).format('HH:mm')}`,
      rRule: event.frequency === 'daily' ? 'FREQ=DAILY' : event.frequency === 'weekly' ? 'FREQ=WEEKLY' : event.frequency === 'monthly' ? 'FREQ=MONTHLY' : undefined
    })))
  }, [agenda, meetings, availability])

  const setPagePatient = patient => {
    dispatcher(dashboardAgendaSlice.actions.setPatient(patient))
  }

  const isTabletOrMobile = useMediaQuery('(max-width:767px)')

  const isMeetingHost = React.useMemo(() => meeting?.host === user?.pk, [meeting, user])

  const isMentoringHost = React.useMemo(() => mentoring?.host === user?.pk, [mentoring, user])

  const isAvailabilityItemOwner = React.useMemo(() => availabilityItem?.supervisor === user?.pk, [availabilityItem, user])

  const isAbleToEdit = React.useMemo(() => (meeting && isMeetingHost) || (mentoring && isMentoringHost) || (availabilityItem && isAvailabilityItemOwner) || (event && isDoctor), [isMeetingHost, event, isDoctor, isMentoringHost, meeting, mentoring, availabilityItem, isAvailabilityItemOwner])

  const DateTimeComponent = ({ value, onValueChange, readOnly }) => {
    return <div className='pt-2'><DateTimePicker slotProps={{ dialog: { title: 'Data e Horário' }, actionBar: { actions: ['accept', 'cancel'] }, field: { className: isTabletOrMobile ? '' : 'w-[263px]' } }} value={moment(value)} onChange={onValueChange} readOnly={readOnly} disablePast /></div>
  }
  const CommandButtonComponent = ({ id, onExecute }) => {
    if (id === 'open') {
      return <div className='w-12 h-12'><button className='hover:bg-opacity-5 bg-black bg-opacity-0 w-full h-full rounded-3xl' onClick={event ? handleEditEvent : meeting ? handleEditMeeting : mentoring ? handleEditMentoring : availabilityItem ? handleEditAvailability : () => { }}>{isAbleToEdit ? <EditIcon className='icon' /> : <VisibilityIcon className='icon' />}</button></div>
    }
    if (id === 'delete') {
      return <div className='w-12 h-12'><button className='hover:bg-opacity-5 bg-black bg-opacity-0 w-full h-full rounded-3xl' onClick={event ? handleDeleteEvent : meeting ? handleDeleteMeeting : mentoring ? handleDeleteMentoring : availabilityItem ? handleDeleteAvailabilityItem : () => { }}>{<DeleteIcon className='icon' />}</button></div>
    }
    if (id === 'close') {
      closeToolTip = onExecute
    }
    return <AppointmentTooltip.CommandButton id={id} onExecute={onExecute} />
  }

  return (
    <section>
      <div className='mx-2'>
        <h1 className='title'>Agenda</h1>
        {isAdmin && <SelectPatientOrDoctor setSearchBy={setSearchBy} searchBy={searchBy} />}
        <div className='flex justify-end flex-wrap'>
          {isDoctor && (
            <button
              onClick={handleCreateEvent}
              type='button'
              className='btn-primary'
            >
              <Add /> Novo atendimento
            </button>
          )}
          {isDoctor && (
            <button
              onClick={handleCreateMentoring}
              type='button'
              className='btn-primary'
            >
              <Add /> Nova mentoria
            </button>
          )}
          {isSupervisor && (
            <button
              onClick={handleCreateAvailabilityItem}
              type='button'
              className='btn-primary'
            >
              <Add /> Adicionar Disponibilidade
            </button>
          )}
          {!isPatientOrFamily && (
            <button
              onClick={handleCreateMeeting}
              type='button'
              className='btn-primary'
            >
              <Add /> Nova reunião
            </button>
          )}
        </div>
      </div>
      {createEventFromSinglePatientPage && <CreateEditEvent fetchAgenda={fetchAgenda} open={createEventFromSinglePatientPage} onCancel={() => setCreateEventFromSinglePatientPage(false)} keepSelectedPatientState />}
      {editEvent && <CreateEditEvent fetchAgenda={fetchAgenda} open={editEvent} onCancel={onCancelEventUpdate} edit readOnly={!isDoctor} keepSelectedPatientState />}
      {editMeeting && <CreateEditMeeting fetchMeetings={fetchMeetings} open={editMeeting} onCancel={onCancelMeetingUpdate} edit readOnly={!isAbleToEdit} />}
      {createEvent && <CreateEditEvent fetchAgenda={fetchAgenda} open={createEvent} onCancel={onCancelEventCreation} />}
      {createMeeting && <CreateEditMeeting fetchMeetings={fetchMeetings} open={createMeeting} onCancel={onCancelMeetingCreation} />}
      {editMentoring && <CreateEditMentoring fetchMentoring={fetchMentoring} fetchMeetings={fetchMeetings} open={editMentoring} onCancel={onCancelMentoringUpdate} edit readOnly={!isAbleToEdit} />}
      {createMentoring && <CreateEditMentoring fetchMentoring={fetchMentoring} fetchMeetings={fetchMeetings} open={createMentoring} onCancel={onCancelMentoringCreation} />}
      {createAvailability && <CreateEditAvailability year={year} month={month} open={createAvailability} onCancel={onCancelAvailabilityCreation} />}
      {editAvailability && <CreateEditAvailability year={year} month={month} open={editAvailability} onCancel={onCancelAvailabilityUpdate} edit readOnly={!isAbleToEdit} />}
      {isDoctor && <div className='mx-2'>
        <h4 className='subtitle'>Visualizar disponibilidade</h4>
        <SelectSupervisor />
      </div>}
      <ConfirmModal content={`Tem certeza que deseja remover o atendimento ${event?.title} com ${pagePatient?.name}?${event?.frequency ? ' Este é um evento recorrente. Todas as ocorrências serão removidas.' : ''}`} title='Remover atendimento' open={deleteEvent} onCancel={() => setDeleteEvent(false)} onConfirm={confirmDeleteEvent} />
      <ConfirmModal content={`Tem certeza que deseja remover a reunião ${meeting?.title}?${meeting?.frequency ? ' Esta é uma reunião recorrente. Todas as ocorrências serão removidas.' : ''}`} title='Remover reunião' open={deleteMeeting} onCancel={() => setDeleteMeeting(false)} onConfirm={confirmDeleteMeeting} />
      <ConfirmModal content={`Tem certeza que deseja remover a mentoria ${mentoring?.title}?`} title='Remover mentoria' open={deleteMentoring} onCancel={() => setDeleteMentoring(false)} onConfirm={confirmDeleteMentoring} />
      <ConfirmModal content={`Tem certeza que deseja remover a disponibilidade?`} title='Remover disponibilidade' open={deleteAvailabilityItem} onCancel={() => setDeleteAvailabilityItem(false)} onConfirm={confirmDeleteAvailabilityItem} />
      <div className='shadow-md bg-white rounded-sm md:mx-2'>
        <Scheduler data={data} locale='pt-BR'>
          <EditingState
            onEditingAppointmentChange={event => {
              if (isAdmin && searchBy === 'doctor') {
                if (event?.patient) {
                  setPagePatient(event.patient)
                } else {
                  setPagePatient(null)
                }
              }
            }}
          />
          <ViewState defaultCurrentDate={moment().format('YYYY-MM-DD')} onCurrentDateChange={e => { setMonth(new Date(e).getMonth()); setYear(new Date(e).getFullYear()) }} />
          <MonthView
            timeTableCellComponent={CellBase}
            dayScaleRowComponent={DayScaleComponent}
            dayScaleCellComponent={DayScaleCell}
          />
          <Appointments />
          <Toolbar />
          <DateNavigator />
          <EditRecurrenceMenu />
          <AppointmentTooltip
            showCloseButton
            showDeleteButton={isAbleToEdit}
            showOpenButton={meeting || event || mentoring || availabilityItem}
            commandButtonComponent={CommandButtonComponent}
            contentComponent={AppointmentTooltipContent}
            onAppointmentMetaChange={(e) => {
              setEvent(null)
              setMeeting(null)
              setMentoring(null)
              setAvailabilityItem(null)
              const data = e.data.parentData || e.data
              if (data.type === 'agenda') {
                setEvent({ ...data, title: data.defaultTitle })
              } else if (data.type === 'meeting') {
                setMeeting({ ...data, title: data.defaultTitle })
              } else if (data.type === 'mentoring') {
                if (data && data.supervisor) {
                  setSupervisor(data.supervisor)
                }
                setMentoring({ ...data, title: data.defaultTitle })
              } else if (data.type === 'availability') {
                setAvailabilityItem(data)
              }
              if (isDoctor) {
                setPagePatient(e.data.patient)
              }
            }}
          />
          <AppointmentForm
            dateEditorComponent={DateTimeComponent}
            messages={appointmentFormMessages}
            readOnly={!isDoctor}
          />
          <Resources
            data={[
              {
                fieldName: 'type',
                title: 'Tipo',
                instances: [
                  {
                    text: "Atendimento",
                    id: 'agenda',
                    color: blue
                  },
                  {
                    text: "Reunião",
                    id: 'meeting',
                    color: green
                  },
                  {
                    text: "Disponibilidade",
                    id: 'availability',
                    color: blueGrey
                  },
                  {
                    text: "Mentoria",
                    id: 'mentoring',
                    color: purple
                  }
                ],
              }
            ]}
          />
        </Scheduler>
      </div>
    </section>
  )
}

const SelectPatientOrDoctor = ({ searchBy, setSearchBy }) => {
  const dispatcher = useDispatch()
  React.useEffect(() => {
    dispatcher(coreSlice.actions.setDoctor(null))
    dispatcher(dashboardAgendaSlice.actions.setPatient(null))
    dispatcher(dashboardAgendaSlice.actions.setAgenda([]))
  }, [dispatcher, searchBy])
  return (
    <div>
      <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
        Buscar por
      </label>
      <label className='flex pb-2' id='searchBy'>
        <div
          onClick={() => setSearchBy('doctor')}
          className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'doctor' ? 'bg-secondary-gray' : 'bg-transparent'}`}
        >
          Profissional
        </div>
        <div
          onClick={() => setSearchBy('patient')}
          className={`text-sm px-4 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'patient' ? 'bg-secondary-gray' : 'bg-transparent'}`}
        >
          Paciente
        </div>
      </label>
      {
        searchBy === 'doctor' ? <SelectDoctor /> : searchBy === 'patient' ? <SelectPatient /> : <></>
      }
      <p className='text-xs'>
        Selecione um profisisonal ou paciente para visualizar os atendimentos da agenda.
      </p>
    </div>
  )
}
