import * as actions from './slice'

export const getFiles = {
  eventName: 'getFiles',
  onSuccess: {
    action: actions.setFiles,
    redux: true
  }
}

export const sendFilesToUpload = {
  eventName: 'uploadFiles',
  onSuccess: {
    action: actions.setUploadFiles,
    redux: true
  }
}

export const finishUploadFiles = {
  eventName: 'finishUploadFiles'
}

export const deleteFile = {
  eventName: 'deleteFile'
}
