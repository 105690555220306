import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { componentsSelectUserSlice, dashboardAgendaSlice } from '../../../reducers'
import { ClearIcon, DateTimePicker } from '@mui/x-date-pickers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import moment from 'moment'
import SelectUser from '../../select-user'
import Loading from '../../loading'
import selectSupervisor from '../../../reducers/components/select-supervisor'
import SelectSupervisor from '../../select-supervisor'

const CreateEditMentoring = ({ open, onCancel, edit, readOnly, fetchMentoring, fetchMeetings }) => {
  const [getMentoringParticipants] = useEvent([dashboardAgendaSlice.eventBus.getMentoringParticipants])
  const dispatcher = useDispatch()
  const [
    createMentoring,
    updateMentoring,
    sendInvite,
    removeInvite
  ] = useEvent([
    { ...dashboardAgendaSlice.eventBus.createMentoring, onSuccess: () => { fetchMentoring(); fetchMeetings() } },
    { ...dashboardAgendaSlice.eventBus.updateMentoring, onSuccess: () => { fetchMentoring(); fetchMeetings() } },
    {
      ...dashboardAgendaSlice.eventBus.sendInvite, onSuccess: () => {
        dispatcher(componentsSelectUserSlice.actions.setUser(null))
        setTimeout(fetchParticipants, 500)
      }
    },
    { ...dashboardAgendaSlice.eventBus.removeInvite, onSuccess: () => { setTimeout(fetchParticipants, 500) } }
  ])
  const mentoring = useSelector(dashboardAgendaSlice.selectors.selectMentoring)
  const [mentoringData, setMentoringData] = React.useState(mentoring)
  const selectedUser = useSelector(componentsSelectUserSlice.selectors.selectUser)
  const [participants, setParticipants] = React.useState([])
  const meetingParticipants = edit ? useSelector(dashboardAgendaSlice.selectors.selectMentoringParticipants) : participants
  const supervisor = useSelector(selectSupervisor.selectors.selectSupervisor)
  const supervisorAvailability = useSelector(dashboardAgendaSlice.selectors.selectAvailability)

  React.useEffect(() => {
    if (!edit) {
      setMentoringInfo('participants', participants)
    }
  }, [participants, edit])

  const setMentoringInfo = (key, value) => {
    setMentoringData({ ...mentoringData, [key]: value })
  }
  const onSuccess = React.useCallback(() => {
    if (edit) {
      updateMentoring.trigger({ ...mentoringData, supervisor: supervisor?.pk })
    } else { createMentoring.trigger({ ...mentoringData, supervisor: supervisor?.pk }) }
    onCancel()
  }, [createMentoring, edit, mentoringData, onCancel, updateMentoring])

  const handleRemoveParticipant = React.useCallback(({ pk: userPk }) => {
    if (edit) {
      removeInvite.trigger({ sk: mentoring?.sk, userPk })
    } else {
      setParticipants(participants => participants.filter(({ pk }) => pk !== userPk))
    }
  }, [mentoring, edit])

  const handleAddParticipant = React.useCallback(() => {
    if (edit) {
      sendInvite.trigger({ sk: mentoring?.sk, userPk: selectedUser?.pk })
    } else {
      setParticipants(participants => [...participants, { pk: selectedUser?.pk, name: selectedUser?.name, email: selectedUser?.email }])
      dispatcher(componentsSelectUserSlice.actions.setUser(null))
    }
  }, [mentoring, selectedUser, edit])

  const fetchParticipants = React.useCallback(() => {
    if (mentoring?.sk) {
      getMentoringParticipants.trigger({ sk: mentoring.sk })
    }
  }, [mentoring])

  React.useEffect(() => {
    fetchParticipants()
  }, [fetchParticipants])

  React.useEffect(() => {
    dispatcher(componentsSelectUserSlice.actions.setUsers([...meetingParticipants, { pk: mentoring?.host }]))
  }, [meetingParticipants, mentoring])

  const isValidDates = React.useMemo(() => {
    let isValid = false
    for (const item of supervisorAvailability) {
      if (mentoringData?.startDate?.valueOf() >= item.startDate && mentoringData?.endDate?.valueOf() <= item.endDate) {
        isValid = true
      }
    }
    return mentoringData?.startDate?.valueOf() < mentoringData?.endDate?.valueOf() && isValid
  }, [mentoringData, supervisorAvailability])
  const isAbleToUpdate = React.useMemo(() => edit && JSON.stringify(mentoringData) !== JSON.stringify(mentoring), [edit, mentoring, mentoringData])
  const isAbleToSave = React.useMemo(() => isValidDates && mentoringData?.title?.length >= 3, [mentoringData, isValidDates])
  const disabled = edit ? (!isAbleToUpdate || !isAbleToSave) : !isAbleToSave
  return (
    <>
      {
        open
          ? (
            <>
              <div
                className='modal'
              >
                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                  <div className='modal-content'>
                    <div className='flex items-start justify-between p-5 border-b border-solid rounded-t'>
                      <h3 className='title'>
                        {readOnly ? 'Informações da mentoria' : edit ? 'Atualizar mentoria' : 'Nova mentoria'}
                      </h3>
                      <button
                        className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                        onClick={onCancel}
                      >
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    <div className='relative p-2 flex-auto'>
                      <h1 className='subtitle'>Supervisor</h1>
                      <SelectSupervisor modal keepState readOnly={readOnly} />
                      <h1 className='subtitle'>Detalhes da mentoria</h1>
                      <input disabled={readOnly} type='text' id='title' placeholder='Título' className='input' value={mentoringData?.title} onChange={({ target: { value } }) => setMentoringInfo('title', value)} />
                      <div className='flex flex-row gap-2'>
                        <div>
                          <h1 className='subtitle'>Início</h1>
                          <DateTimePicker disabled={readOnly} value={moment(mentoringData?.startDate)} onChange={value => setMentoringInfo('startDate', value.valueOf())} />
                        </div>
                        <div>
                          <h1 className='subtitle'>Fim</h1>
                          <DateTimePicker disabled={readOnly} value={moment(mentoringData?.endDate)} onChange={value => setMentoringInfo('endDate', value.valueOf())} />
                        </div>
                      </div>
                      {!isValidDates && !readOnly && <p className='text-red-700 text-sm'>Data inválida.</p>}
                      <h1 className='subtitle'>Participantes</h1>
                      <div className={`h-36 ${readOnly ? 'opacity-70' : ''}`}>
                        {(getMentoringParticipants.isWorking || sendInvite.isWorking || removeInvite.isWorking) ? <div className='flex flex-col items-center'><Loading /></div> :
                          meetingParticipants.length === 0 ? <p className='text-sm'>Não há participantes.</p> :
                            meetingParticipants.length > 0 ? <div className='input max-h-36 h-36 flex overflow-auto flex-col'>
                              <table className='w-full'>
                                <thead>
                                  <tr>
                                    <th className='text-xs'>Nome</th>
                                    <th className='text-xs'>E-mail</th>
                                    {!readOnly && <th className='text-xs'>Remover</th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {meetingParticipants.map((participant, key) => (
                                    <tr key={key} className='border-b h-8'>
                                      <td className='text-center text-xs flex justify-center'>
                                        {participant?.name}
                                      </td>
                                      <td className='text-center text-xs'>
                                        {participant?.email}
                                      </td>
                                      {!readOnly && <td className='text-center text-xs'>
                                        {!(participant.pk === mentoring.host) && <ClearIcon onClick={() => { handleRemoveParticipant(participant) }} className='icon cursor-pointer hover:text-gray-900' />}
                                      </td>}
                                    </tr>
                                  ))
                                  }
                                </tbody>
                              </table>
                            </div>
                              : <></>}
                      </div>
                      {!readOnly && <div>
                        <h1 className='subtitle'>Adicionar participante</h1>
                        <div className='flex flex-row items-end'>
                          <SelectUser />
                          <button className='btn-primary' onClick={handleAddParticipant} disabled={!selectedUser?.pk}>Adicionar</button>
                        </div>
                      </div>}
                    </div>
                    <div className='flex items-center justify-end rounded-b px-4'>
                      <button
                        className='btn-secondary'
                        type='button'
                        onClick={onCancel}
                      >
                        Fechar
                      </button>
                      {!readOnly &&
                        <button
                          className={`btn-primary ${disabled ? 'btn-disabled' : ''}`}
                          type='button'
                          onClick={onSuccess}
                          disabled={disabled}
                        >
                          {edit ? 'Salvar' : 'Criar'}
                        </button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='opacity-25 fixed inset-0 z-40 bg-black' />
            </>)
          : null
      }
    </>
  )
}
export default CreateEditMentoring
