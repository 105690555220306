import React from 'react'
import { useSelector } from 'react-redux'
import { coreSlice, dashboardAgendaSlice } from '../../../reducers'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import moment from 'moment'

const CreateEditAvailability = ({ open, onCancel, edit, readOnly, year, month }) => {
  const [getAvailability] = useEvent([dashboardAgendaSlice.eventBus.getAvailability])
  const user = useSelector(coreSlice.selectors.selectUser)
  const fetchAvailability = React.useCallback(() => {
    if (user) {
      getAvailability.trigger({ year, month, supervisor: user?.pk })
    }
  }, [user])
  const [upsertAvailability] = useEvent([{ ...dashboardAgendaSlice.eventBus.upsertAvailability, onSuccess: fetchAvailability }])
  const availabilityItem = useSelector(dashboardAgendaSlice.selectors.selectAvailabilityItem)
  const [availabilityItemData, setAvailabilityItemData] = React.useState(availabilityItem)
  const supervisorAvailability = useSelector(dashboardAgendaSlice.selectors.selectAvailability)

  const setAvailabilityItemInfo = (key, value) => {
    setAvailabilityItemData({ ...availabilityItemData, [key]: value })
  }
  const onSuccess = React.useCallback(() => {
    if (edit) {
      upsertAvailability.trigger(availabilityItemData)
    } else { upsertAvailability.trigger(availabilityItemData) }
    onCancel()
  }, [upsertAvailability, edit, availabilityItemData, fetchAvailability, onCancel, upsertAvailability])

  const isValidDates = React.useMemo(() => {
    let isValid = true
    for (const item of supervisorAvailability) {
      if (availabilityItemData?.startDate?.valueOf() >= item.startDate && availabilityItemData?.startDate?.valueOf() <= item.endDate ||
        availabilityItemData?.endDate?.valueOf() >= item.startDate && availabilityItemData?.endDate?.valueOf() <= item.endDate
      ) {
        isValid = false
      }
    }
    return availabilityItemData?.startDate?.valueOf() < availabilityItemData?.endDate?.valueOf() && (isValid || edit)
  }, [availabilityItemData, supervisorAvailability])
  const isAbleToUpdate = React.useMemo(() => edit && JSON.stringify(availabilityItemData) !== JSON.stringify(availabilityItem), [edit, availabilityItem, availabilityItemData])
  const isAbleToSave = React.useMemo(() => isValidDates, [isValidDates])
  const disabled = edit ? (!isAbleToUpdate || !isAbleToSave) : !isAbleToSave
  return (
    <>
      {
        open
          ? (
            <>
              <div
                className='modal'
              >
                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                  <div className='modal-content'>
                    <div className='flex items-start justify-between p-5 border-b border-solid rounded-t'>
                      <h3 className='title'>
                        {readOnly ? 'Informações de disponibilidade' : edit ? 'Atualizar disponibilidade' : 'Nova disponibilidade'}
                      </h3>
                      <button
                        className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                        onClick={onCancel}
                      >
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    <div className='relative p-2 flex-auto'>
                      <div className='flex flex-row gap-2'>
                        <div>
                          <h1 className='subtitle'>Início</h1>
                          <DateTimePicker disabled={readOnly || edit} value={moment(availabilityItemData?.startDate)} onChange={value => setAvailabilityItemInfo('startDate', value.valueOf())} />
                        </div>
                        <div>
                          <h1 className='subtitle'>Fim</h1>
                          <DateTimePicker disabled={readOnly} value={moment(availabilityItemData?.endDate)} onChange={value => setAvailabilityItemInfo('endDate', value.valueOf())} />
                        </div>
                      </div>
                      {!isValidDates && !readOnly && <p className='text-red-700 text-sm'>Data inválida.</p>}
                    </div>
                    <div className='flex items-center justify-end rounded-b px-4'>
                      <button
                        className='btn-secondary'
                        type='button'
                        onClick={onCancel}
                      >
                        Fechar
                      </button>
                      {!readOnly &&
                        <button
                          className={`btn-primary ${disabled ? 'btn-disabled' : ''}`}
                          type='button'
                          onClick={onSuccess}
                          disabled={disabled}
                        >
                          {edit ? 'Salvar' : 'Criar'}
                        </button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='opacity-25 fixed inset-0 z-40 bg-black' />
            </>)
          : null
      }
    </>
  )
}
export default CreateEditAvailability
