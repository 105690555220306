import React from 'react'
import SelectPatient from '../../../select-patient'
import { useSelector } from 'react-redux'
import { dashboardAgendaSlice, dashboardPatientSlice } from '../../../../reducers'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import moment from 'moment'
import SelectDoctor from '../../../select-doctor'

const CreateEditEvent = ({ open, onCancel, edit, readOnly, fetchAgenda }) => {
  const [
    addEventToAgenda,
    updateEventOnAgenda
  ] = useEvent([
    { ...dashboardAgendaSlice.eventBus.addEventToAgenda, onSuccess: () => { setTimeout(fetchAgenda, 500) } },
    { ...dashboardAgendaSlice.eventBus.updateEventOnAgenda, onSuccess: () => { setTimeout(fetchAgenda, 500) } }
  ])
  const event = useSelector(dashboardAgendaSlice.selectors.selectEvent)
  const [eventData, setEventData] = React.useState(event)
  const patient = useSelector(dashboardPatientSlice.selectors.selectPatient)

  const eventToSave = React.useMemo(() => ({ ...eventData, startDate: edit ? event?.startDate : eventData?.startDate, newStartDate: eventData?.startDate === event?.startDate ? undefined : eventData?.startDate, patient: patient?.pk }), [event.startDate, eventData, patient, edit])

  const setEventInfo = (key, value) => {
    setEventData({ ...eventData, [key]: value })
  }
  const onSuccess = React.useCallback(() => {
    if (edit) {
      updateEventOnAgenda.trigger(eventToSave)
    } else { addEventToAgenda.trigger(eventToSave) }
    onCancel()
    fetchAgenda()
  }, [addEventToAgenda, edit, eventToSave, fetchAgenda, onCancel, updateEventOnAgenda])

  const handleFrequencyChange = (frequency) => {
    if (eventData.frequency && eventData.frequency === frequency) {
      setEventInfo('frequency', undefined)
    } else {
      setEventInfo('frequency', frequency)
    }
  }

  React.useEffect(() => {

  }, [])

  const isValidDates = React.useMemo(() => eventData?.startDate?.valueOf() < eventData?.endDate?.valueOf(), [eventData])
  const isAbleToUpdate = React.useMemo(() => edit && JSON.stringify(eventToSave) !== JSON.stringify(event), [edit, event, eventToSave])
  const isAbleToSave = React.useMemo(() => isValidDates && eventData?.title?.length >= 3 && patient?.pk, [eventData, isValidDates, patient])
  const disabled = edit ? (!isAbleToUpdate || !isAbleToSave) : !isAbleToSave
  return (
    <>
      {
        open
          ? (
            <>
              <div
                className='modal'
              >
                <div className='relative w-auto my-6 mx-auto max-w-3xl'>
                  <div className='modal-content'>
                    <div className='flex items-start justify-between p-5 border-b border-solid rounded-t'>
                      <h3 className='text-xl font-medium'>
                        {readOnly ? 'Informações do atendimento' : edit ? 'Atualizar atendimento' : 'Novo atendimento'}
                      </h3>
                      <button
                        className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                        onClick={onCancel}
                      >
                        <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                          ×
                        </span>
                      </button>
                    </div>
                    <div className='relative p-2 flex-auto'>
                      <h1 className='subtitle'>Paciente</h1>
                      <SelectPatient readOnly={true} modal keepState={true} />
                      <h1 className='subtitle'>Terapeuta</h1>
                      <SelectDoctor readOnly={true} modal keepState={true} />
                      <h1 className='subtitle'>Detalhes do atendimento</h1>
                      <input disabled={readOnly} type='text' id='title' placeholder='Título' className='input' value={eventData?.title} onChange={({ target: { value } }) => setEventInfo('title', value)} />
                      <div className='flex flex-row gap-2'>
                        <div>
                          <h1 className='subtitle'>Início</h1>
                          <DateTimePicker disabled={readOnly} value={moment(eventData?.startDate)} onChange={value => setEventInfo('startDate', value.valueOf())} />
                        </div>
                        <div>
                          <h1 className='subtitle'>Fim</h1>
                          <DateTimePicker disabled={readOnly} value={moment(eventData?.endDate)} onChange={value => setEventInfo('endDate', value.valueOf())} />
                        </div>
                      </div>
                      {!isValidDates && !readOnly && <p className='text-red-700 text-sm'>Data inválida.</p>}
                      <h1 className='subtitle'>Frequência</h1>
                      <div className='w-64 flex flex-row justify-between'>
                        <div className={`flex flex-row gap-2 ${readOnly ? 'opacity-70' : ''}`}><input type='checkbox' checked={eventData?.frequency === 'daily'} onChange={() => handleFrequencyChange('daily')} disabled={readOnly} /><p>Diária</p></div>
                        <div className={`flex flex-row gap-2 ${readOnly ? 'opacity-70' : ''}`}><input type='checkbox' checked={eventData?.frequency === 'weekly'} onChange={() => handleFrequencyChange('weekly')} disabled={readOnly} /><p>Semanal</p></div>
                        <div className={`flex flex-row gap-2 ${readOnly ? 'opacity-70' : ''}`}><input type='checkbox' checked={eventData?.frequency === 'monthly'} onChange={() => handleFrequencyChange('monthly')} disabled={readOnly} /><p>Mensal</p></div>
                      </div>
                      <h1 className='subtitle'>Mais Informações</h1>
                      <textarea disabled={readOnly} type='text' rows={4} className='input' value={eventData?.notes} onChange={({ target: { value } }) => setEventInfo('notes', value)} />
                    </div>
                    <div className='flex items-center justify-end rounded-b'>
                      <button
                        className='btn-secondary'
                        type='button'
                        onClick={onCancel}
                      >
                        {readOnly ? 'Fechar' : 'Cancelar'}
                      </button>
                      {!readOnly &&
                        <button
                          className={`btn-primary ${disabled ? 'btn-disabled' : ''}`}
                          type='button'
                          onClick={onSuccess}
                          disabled={disabled}
                        >
                          {edit ? 'Atualizar' : 'Criar'}
                        </button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className='opacity-25 fixed inset-0 z-40 bg-black' />
            </>)
          : null
      }
    </>
  )
}
export default CreateEditEvent
