import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { adminSlice, componentsSelectDoctorSlice, componentsSelectPatientSlice, coreSlice, patientSlice } from '../../../../reducers'
import SelectPatient from '../../../select-patient'
import { useEvent } from '@emerald-works/react-event-bus-client'

const EditUser = () => {
  const { role } = useSelector(coreSlice.selectors.selectUser)
  const user = useSelector(adminSlice.selectors.selectUser)
  const patient = useSelector(componentsSelectPatientSlice.selectors.selectPatient)
  const [selectedUser, setSelectedUser] = React.useState(user)
  const [getPatient] = useEvent([componentsSelectPatientSlice.eventBus.getPatient])
  const [updateUserInfo] = useEvent([adminSlice.eventBus.updateUser])
  const [getDoctor] = useEvent([componentsSelectDoctorSlice.eventBus.getDoctor])
  const doctor = useSelector(componentsSelectDoctorSlice.selectors.selectDoctor)

  const dispatcher = useDispatch()
  const setUser = user => {
    dispatcher(adminSlice.actions.setUser(user))
  }

  const setPatientDoctors = doctors => {
    dispatcher(patientSlice.actions.setPatientDoctors(doctors))
  }

  const updateUser = React.useCallback((field, value) => {
    setSelectedUser({ ...selectedUser, [field]: value })
  }, [selectedUser])

  React.useEffect(() => {
    if (selectedUser?.patientDoc && !patient) {
      getPatient.trigger({ patientDoc: selectedUser?.patientDoc })
    }
  }, [getPatient, patient, selectedUser.patientDoc])

  const updatedUser = React.useMemo(() => {
    const userInfo = { ...selectedUser }
    if (selectedUser?.role === 'patient') {
      userInfo.patientDoc = patient?.doc
    } else {
      delete userInfo.patientDoc
    }
    if (selectedUser?.role !== 'doctor') {
      delete userInfo.isSupervisor
    }
    return userInfo
  }, [doctor, patient, selectedUser])

  const save = () => {
    if (updatedUser.role === 'patient') {
      if (!updatedUser?.patientDoc) {
        return
      }
    }
    updateUserInfo.trigger(updatedUser)
    dispatcher(adminSlice.actions.setUser(updatedUser))
  }

  const isEmptyPatientDoc = React.useMemo(() => !updatedUser?.patientDoc && !getPatient.isWorking && selectedUser.role === 'patient', [getPatient.isWorking, selectedUser, updatedUser.patientDoc])
  const isAbleToSave = React.useMemo(() => !updateUserInfo.isWorking && !getPatient.isWorking && !getDoctor.isWorking && JSON.stringify(updatedUser) !== JSON.stringify(user), [user, getDoctor.isWorking, getPatient.isWorking, updateUserInfo.isWorking, updatedUser]) && !isEmptyPatientDoc

  React.useEffect(() => {
    if (user?.supervisor?.length > 0 && !doctor) {
      getDoctor.trigger({ pk: user?.supervisor })
    }
  }, [user.supervisor, doctor, getDoctor])

  return (
    <section className='px-2 font-inter flex flex-col w-full'>
      <div className='inline-flex items-center justify-between'>
        <div className='title inline-flex'>
          <button
            className='outline-none border-none'
            onClick={() => {
              setUser(null)
            }}
          >
            Usuários
          </button>
          <span>{'>'}</span>
          <p className='truncate'>{user.name}</p>
        </div>
      </div>
      <div className='flex justify-end'>
        <button className={isAbleToSave ? 'btn-primary' : 'btn-disabled'} disabled={!isAbleToSave} onClick={save}>Salvar</button>
        <button className='btn-secondary' onClick={() => { setUser(null); setPatientDoctors([]) }}>Voltar</button>
      </div>
      <div className='w-full'>
        <div className='mb-5'>
          <label htmlFor='name' className='label'>Nome</label>
          <input type='text' id='name' className='input' value={selectedUser?.name} onChange={({ target: { value } }) => updateUser('name', value)} />
        </div>
        <div className='mb-5'>
          <label htmlFor='role' className='label'>Papel</label>
          <select type='text' id='role' className='input' value={selectedUser?.role} onChange={({ target: { value } }) => updateUser('role', value)}>
            <option value='patient'>Paciente</option>
            <option value='family'>Familiar</option>
            <option value='doctor'>Profissional</option>
            <option value='admin'>Administrador</option>
            {role === 'owner' && <option value='owner'>Proprietário</option>}
          </select>
        </div>
        {selectedUser?.role === 'patient' &&
          <>
            <label className='label'>Paciente</label>
            <SelectPatient />
            {isEmptyPatientDoc && <p className='text-red-800 text-sm'>Você precisa selecionar um paciente.</p>}
          </>}
        {selectedUser?.role === 'doctor' && <div className='flex flex-row gap-2'><input type='checkbox' checked={selectedUser?.isSupervisor} onChange={() => updateUser('isSupervisor', !selectedUser.isSupervisor)} /><p>Supervisor</p></div>}
      </div>
    </section>
  )
}

export default EditUser
