import * as React from 'react'
import { ViewState, EditingState, Resources } from '@devexpress/dx-react-scheduler'
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  AppointmentTooltip,
  AppointmentForm,
  EditRecurrenceMenu
} from '@devexpress/dx-react-scheduler-material-ui'
import moment from 'moment'
import 'moment/locale/pt-br'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardAttendanceSlice, coreSlice, dashboardPatientSlice, dashboardAgendaSlice, componentsSelectPatientSlice, componentsSelectDoctorSlice } from '../../../../reducers'
import { PagesContext } from '../../../../contexts/pages'
import { useMediaQuery } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import CreateEditEvent from './create-edit-event'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmModal from '../../../confirm-modal'
import { initialState } from '../../../../reducers/dashboard/agenda/slice'
import { blue } from '@mui/material/colors'
import { useNavigate, useParams } from 'react-router-dom'

const appointmentFormMessages = {
  afterLabel: 'Até',
  allDayLabel: 'Dia todo',
  commitCommand: 'Salvar',
  daily: 'Diariamente',
  daysLabel: 'dias',
  detailsLabel: 'Detalhes do atendimento',
  endRepeatLabel: 'Repetir até',
  everyLabel: 'everyLabel',
  firstLabel: 'firstLabel',
  fourthLabel: 'fourthLabel',
  lastLabel: 'lastLabel',
  monthly: 'Mensalmante',
  monthsLabel: 'monthsLabel',
  moreInformationLabel: 'Mais informações',
  never: 'Nunca',
  notesLabel: 'Mais informações',
  occurrencesLabel: 'vezes',
  ofEveryMonthLabel: 'ofEveryMonthLabel',
  ofLabel: 'ofLabel',
  onLabel: '',
  repeatEveryLabel: 'Repetir a cada',
  repeatLabel: 'Recorrente',
  secondLabel: 'secondLabel',
  theLabel: 'theLabel',
  thirdLabel: 'thirdLabel',
  titleLabel: 'Título',
  weekly: 'Semanalmente',
  weeksOnLabel: 'weeksOnLabel',
  yearly: 'Anualmente',
  yearsLabel: 'yearsLabel'
}

const DayScaleCell = ({ startDate, today }) => {
  const width = React.useRef(window.innerWidth)
  const dateString = moment(startDate)
    .locale('pt-br')
    .format('dddd')
    .toUpperCase()
  const date = width?.current >= 1000 ? dateString : dateString.slice(0, 3)
  return <td className='text-white text-center w-[15%] py-5'>{date}</td>
}

const DayScaleComponent = ({ children, ...props }) => {
  return (
    <tr className='inline-flex w-full justify-between bg-primary-blue'>
      {children}
    </tr>
  )
}

let closeToolTip = null
export default function PatientAgenda() {
  const patient = useSelector(dashboardPatientSlice.selectors.selectPatient)
  const {
    dashBoardPageInfo,
    setDashBoardPageInfo
  } = React.useContext(PagesContext)
  const [searchBy, setSearchBy] = React.useState('doctor')
  const agenda = useSelector(dashboardAgendaSlice.selectors.selectAgenda)
  const user = useSelector(coreSlice.selectors.selectUser)
  const pagePatient = useSelector(dashboardAgendaSlice.selectors.selectPatient)
  const selectedDoctor = useSelector(componentsSelectDoctorSlice.selectors.selectDoctor)
  const [year, setYear] = React.useState(new Date().getFullYear())
  const [month, setMonth] = React.useState(new Date().getMonth())
  const [data, setData] = React.useState([])
  const dispatcher = useDispatch()
  const isDoctor = React.useMemo(() => user?.role === 'doctor', [user.role])
  const isOwner = React.useMemo(() => user?.role === 'owner', [user.role])
  const isAdmin = React.useMemo(() => user?.role === 'admin' || user?.role === 'owner', [user.role])
  const isPatientOrFamily = React.useMemo(() => user?.role === 'patient' || user?.role === 'family', [user.role])
  const isNotAdmin = React.useMemo(() => isDoctor || isOwner || isPatientOrFamily, [isDoctor, isPatientOrFamily, isOwner])
  const roleInfo = React.useMemo(() => (isDoctor || ((isAdmin || isOwner) && searchBy === 'doctor')) ? 'patient' : 'doctor', [isAdmin, isDoctor, searchBy])
  const [editEvent, setEditEvent] = React.useState(false)
  const [createEvent, setCreateEvent] = React.useState(false)
  const [deleteEvent, setDeleteEvent] = React.useState(false)
  const [createEventFromSinglePatientPage, setCreateEventFromSinglePatientPage] = React.useState(false)
  const event = useSelector(dashboardAgendaSlice.selectors.selectEvent)
  const patientFromSinglePatientPage = useSelector(dashboardPatientSlice.selectors.selectPatient)
  const [getPatientAgenda] = useEvent([dashboardAgendaSlice.eventBus.getPatientAgenda])

  React.useEffect(() => {
    dispatcher(dashboardAgendaSlice.actions.setAgenda([]))
    dispatcher(componentsSelectPatientSlice.actions.setPatient({}))
    dispatcher(componentsSelectDoctorSlice.actions.setDoctor({}))
  }, [dispatcher])

  const navigate = useNavigate()
  const { tenantKey } = useParams()

  const isAnotherUserData = React.useMemo(() => isAdmin && selectedDoctor?.pk && (selectedDoctor?.pk !== user?.pk), [selectedDoctor, user, isAdmin])

  const fetchAgenda = React.useCallback(() => {
    getPatientAgenda.trigger({ pk: patient.pk, year, month })
  }, [month, patient, year])

  const [
    removeEventFromAgenda,
  ] = useEvent([
    { ...dashboardAgendaSlice.eventBus.removeEventFromAgenda, onSuccess: fetchAgenda }
  ])

  const setEvent = React.useCallback((eventInfo) => {
    if (eventInfo) {
      const { startDate, endDate } = eventInfo
      var info = {
        ...eventInfo,
        startDate: startDate.valueOf(),
        endDate: endDate.valueOf()
      }
    } else {
      var info = null
    }
    dispatcher(dashboardAgendaSlice.actions.setEvent(info))
  }, [dispatcher])

  const handleEditEvent = () => {
    closeToolTip && closeToolTip()
    setEditEvent(true)
    dispatcher(componentsSelectPatientSlice.actions.setPatient(pagePatient))
    dispatcher(componentsSelectDoctorSlice.actions.setDoctor(event?.doctor))
  }

  const handleCreateEvent = React.useCallback(() => {
    setEvent(initialState.event)
    setCreateEvent(true)
  }, [setEvent])

  const onCancelEventUpdate = () => {
    setEditEvent(false)
  }

  const onCancelEventCreation = () => {
    setCreateEvent(false)
  }

  const handleDeleteEvent = () => {
    closeToolTip && closeToolTip()
    setDeleteEvent(true)
  }

  const confirmDeleteEvent = React.useCallback(() => {
    removeEventFromAgenda.trigger({ startDate: event.startDate })
    setDeleteEvent(false)
  }, [event])


  React.useEffect(() => {
    fetchAgenda()
  }, [fetchAgenda])

  React.useEffect(() => {
    if (dashBoardPageInfo.autoOpenNewEvent) {
      setCreateEventFromSinglePatientPage(true)
      setEvent(initialState.event)
      dispatcher(componentsSelectPatientSlice.actions.setPatient(patientFromSinglePatientPage))
      dispatcher(componentsSelectDoctorSlice.actions.setDoctor(user))
      setDashBoardPageInfo((state) => ({ ...state, autoOpenNewEvent: false }))
    }
  }, [dashBoardPageInfo, dashBoardPageInfo.autoOpenNewEvent, dispatcher, handleCreateEvent, patientFromSinglePatientPage, setDashBoardPageInfo, setEvent])

  const AppointmentTooltipContent = ({
    appointmentData,
    ...restProps
  }) => {
    const {
      setDashBoardPage,
      setSinglePatientPage,
      SinglePatientPages,
      DashBoardPages
    } = React.useContext(PagesContext)
    const beginAppointment = () => {
      if (isDoctor) {
        dispatcher(dashboardPatientSlice.actions.setPatient(appointmentData[roleInfo]))
        dispatcher(dashboardAttendanceSlice.actions.setPatient(appointmentData[roleInfo]))
        setDashBoardPage(DashBoardPages.PATIENTS)
        setSinglePatientPage(SinglePatientPages.PATIENT_ATTENDANCE)
      } else {
        setDashBoardPage(DashBoardPages.ATTENDANCE)
      }
    }

    React.useEffect(() => {
      if (isPatientOrFamily) {
        setPagePatient(patient)
      } else if (roleInfo === 'patient') {
        setPagePatient(appointmentData[roleInfo])
      }
    }, [appointmentData])

    const { location: { origin } } = window

    return (
      <AppointmentTooltip.Content
        appointmentData={appointmentData}
        {...restProps}
        children={
          <>
            {restProps.children}
            {isNotAdmin &&
              <div className='flex justify-end'>
                <button onClick={beginAppointment} className='btn-primary'>
                  {isEventDoctor ? 'Iniciar Atendimento' : 'Assistir Atendimento'}
                </button>
              </div>}
          </>
        }
      />
    )
  }

  const CellBase = React.memo(
    ({ startDate, formatDate, otherMonth, today }) => {
      const isFirstMonthDay = startDate.getDate() === 1
      const formatOptions = isFirstMonthDay
        ? {
          day: 'numeric',
          month: 'long'
        }
        : { day: 'numeric' }
      const handleDoubleClick = () => {
        handleCreateEvent()
        setEvent({ startDate: startDate?.valueOf(), endDate: startDate?.valueOf() + (3600) })
      }
      return (
        <td
          className={`${today ? 'bg-light-gray' : ''}${otherMonth ? 'opacity-50' : ''} align-top h-24 border-2`}
          onDoubleClick={isDoctor ? handleDoubleClick : null}
        >
          <div className='p-2'>{formatDate(startDate, formatOptions)}</div>
        </td>
      )
    }
  )

  React.useEffect(() => {
    setData([...agenda.map(item => ({ type: 'agenda', ...item }))].map((event) => ({
      id: event.startDate, ...event, defaultTitle: event.title, title: `${event.title} das ${moment(event.startDate).format('HH:mm')} às ${moment(
        event.endDate
      ).format('HH:mm')}`,
      rRule: event.frequency === 'daily' ? 'FREQ=DAILY' : event.frequency === 'weekly' ? 'FREQ=WEEKLY' : event.frequency === 'monthly' ? 'FREQ=MONTHLY' : undefined
    })))
  }, [agenda])

  const setPagePatient = patient => {
    dispatcher(dashboardAgendaSlice.actions.setPatient(patient))
  }

  const isTabletOrMobile = useMediaQuery('(max-width:767px)')

  const isEventDoctor = React.useMemo(() => event?.doctor?.pk === user?.pk, [event, user])

  const isAbleToEdit = React.useMemo(() => (event && isEventDoctor), [isEventDoctor, event])

  const DateTimeComponent = ({ value, onValueChange, readOnly }) => {
    return <div className='pt-2'><DateTimePicker slotProps={{ dialog: { title: 'Data e Horário' }, actionBar: { actions: ['accept', 'cancel'] }, field: { className: isTabletOrMobile ? '' : 'w-[263px]' } }} value={moment(value)} onChange={onValueChange} readOnly={readOnly} disablePast /></div>
  }
  const CommandButtonComponent = ({ id, onExecute }) => {
    if (id === 'open') {
      return <div className='w-12 h-12'><button className='hover:bg-opacity-5 bg-black bg-opacity-0 w-full h-full rounded-3xl' onClick={event ? handleEditEvent : () => { }}>{isAbleToEdit ? <EditIcon className='icon' /> : <VisibilityIcon className='icon' />}</button></div>
    }
    if (id === 'delete') {
      return <div className='w-12 h-12'><button className='hover:bg-opacity-5 bg-black bg-opacity-0 w-full h-full rounded-3xl' onClick={event ? handleDeleteEvent : () => { }}>{<DeleteIcon className='icon' />}</button></div>
    }
    if (id === 'close') {
      closeToolTip = onExecute
    }
    return <AppointmentTooltip.CommandButton id={id} onExecute={onExecute} />
  }

  return (
    <section>
      {createEventFromSinglePatientPage && <CreateEditEvent fetchAgenda={fetchAgenda} open={createEventFromSinglePatientPage} onCancel={() => setCreateEventFromSinglePatientPage(false)} keepSelectedPatientState />}
      {editEvent && <CreateEditEvent fetchAgenda={fetchAgenda} open={editEvent} onCancel={onCancelEventUpdate} edit readOnly={!isEventDoctor} keepSelectedPatientState />}
      {createEvent && <CreateEditEvent fetchAgenda={fetchAgenda} open={createEvent} onCancel={onCancelEventCreation} />}
      <ConfirmModal content={`Tem certeza que deseja remover o atendimento ${event?.title} com ${pagePatient?.name}?${event?.frequency ? ' Este é um evento recorrente. Todas as ocorrências serão removidas.' : ''}`} title='Remover atendimento' open={deleteEvent} onCancel={() => setDeleteEvent(false)} onConfirm={confirmDeleteEvent} />
      <div className='shadow-md bg-white rounded-sm md:mx-2'>
        <Scheduler data={data} locale='pt-BR'>
          <EditingState
            onEditingAppointmentChange={event => {
              if (isAdmin && searchBy === 'doctor') {
                if (event?.patient) {
                  setPagePatient(event.patient)
                } else {
                  setPagePatient(null)
                }
              }
            }}
          />
          <ViewState defaultCurrentDate={moment().format('YYYY-MM-DD')} onCurrentDateChange={e => { setMonth(new Date(e).getMonth()); setYear(new Date(e).getFullYear()) }} />
          <MonthView
            timeTableCellComponent={CellBase}
            dayScaleRowComponent={DayScaleComponent}
            dayScaleCellComponent={DayScaleCell}
          />
          <Appointments />
          <Toolbar />
          <DateNavigator />
          <EditRecurrenceMenu />
          <AppointmentTooltip
            showCloseButton
            showDeleteButton={isAbleToEdit}
            showOpenButton
            commandButtonComponent={CommandButtonComponent}
            contentComponent={AppointmentTooltipContent}
            onAppointmentMetaChange={(e) => {
              setEvent(null)
              const data = e.data.parentData || e.data
              setEvent({ ...data, title: data.defaultTitle })
              if (isDoctor) {
                setPagePatient(e.data.patient)
              }
            }}
          />
          <AppointmentForm
            dateEditorComponent={DateTimeComponent}
            messages={appointmentFormMessages}
            readOnly={!isAbleToEdit}
          />
          <Resources
            data={[
              {
                fieldName: 'type',
                title: 'Tipo',
                instances: [
                  {
                    text: "Atendimento",
                    id: 'agenda',
                    color: blue
                  }
                ],
              }
            ]}
          />
        </Scheduler>
      </div>
    </section>
  )
}
