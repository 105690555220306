import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { coreSlice, dashboardChatSlice } from '../../../reducers'
import { EventContext } from '../../../contexts/event'
import Loading from '../../loading'
import { PagesContext } from '../../../contexts/pages'
import ForumIcon from '@mui/icons-material/Forum'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import VideocamIcon from '@mui/icons-material/Videocam'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import FolderIcon from '@mui/icons-material/Folder'
import { FormHelperText, MenuItem, Select, TextField } from '@mui/material'

const DashboardLeftBar = () => {
  const { userLoading } = React.useContext(EventContext)
  const { dashBoardPage, setDashBoardPage, DashBoardPages, setShowLeftBar, showLeftBar } =
    React.useContext(PagesContext)
  const user = useSelector(coreSlice.selectors.selectUser)
  const patient = useSelector(coreSlice.selectors.selectPatient)
  const isLoading = userLoading || !user
  const mustSelectAPatient = user?.role === 'family' && !patient?.pk
  const dispatcher = useDispatch()
  const DoctorPatientDashboardItems = () => {
    return (
      <>
        <div
          className={
            dashBoardPage === DashBoardPages.CHAT
              ? 'selected-left-drawer-item cursor-pointer'
              : 'left-drawer-item cursor-pointer'
          }
          onClick={() => {
            if (patient?.pk) {
              dispatcher(dashboardChatSlice.actions.setPatient(patient))
            }
            setDashBoardPage(DashBoardPages.CHAT)
          }}
        >
          <ForumIcon className='icon' />
          Mensagens
        </div>
      </>
    )
  }

  const DoctorAndAdminDashboardItems = () => {
    return (
      <>
        <div
          className={
            dashBoardPage === DashBoardPages.PATIENTS
              ? 'selected-left-drawer-item cursor-pointer'
              : 'left-drawer-item cursor-pointer'
          }
          onClick={() => setDashBoardPage(DashBoardPages.PATIENTS)}
        >
          <EmojiEmotionsIcon className='icon' />
          Pacientes
        </div>
        <div
          className={
            dashBoardPage === DashBoardPages.FILES
              ? 'selected-left-drawer-item cursor-pointer'
              : 'left-drawer-item cursor-pointer'
          }
          onClick={() => setDashBoardPage(DashBoardPages.FILES)}
        >
          <FolderIcon className='icon' />
          Arquivos
        </div>
      </>
    )
  }

  const PatientFamilyDashboardItems = () => {
    return (
      <>
        <div
          className={
            dashBoardPage === DashBoardPages.ATTENDANCE
              ? 'selected-left-drawer-item cursor-pointer'
              : 'left-drawer-item cursor-pointer'
          }
          onClick={() =>
            setDashBoardPage(DashBoardPages.ATTENDANCE)}
        >
          <VideocamIcon className='icon' />
          Atendimento
        </div>
      </>
    )
  }

  const Items = () => {
    return (
      <>
        <SwitchPatient />
        {!mustSelectAPatient &&
          <>
            <div
              className={
                dashBoardPage === DashBoardPages.AGENDA
                  ? 'selected-left-drawer-item cursor-pointer'
                  : 'left-drawer-item cursor-pointer'
              }
              onClick={() => setDashBoardPage(DashBoardPages.AGENDA)}
            >
              <CalendarMonthIcon className='icon' />
              Agenda
            </div>
            {user?.role === 'doctor' || user?.role === 'admin' || user?.role === 'owner' ? (
              <DoctorAndAdminDashboardItems />
            ) : (user?.role === 'patient' || user?.role === 'family') ? (
              <PatientFamilyDashboardItems />
            ) : <></>}
            {(user?.role === 'doctor' || user?.role === 'owner' || user?.role === 'patient' || user?.role === 'family') && <DoctorPatientDashboardItems />}
          </>}
      </>
    )
  }

  return (
    <>
      <div className={showLeftBar ? 'left-bar-open' : 'left-bar-closed'}>
        <div className='flex flex-col border-b shadow-sm h-screen w-min-40 w-40 bg-white' onClick={() => setShowLeftBar(false)}>
          {isLoading
            ? <div className='flex justify-center items-center w-full pt-10'> <Loading size={5} /></div>
            : <Items />}
        </div>
      </div>
      <div className={`w-screen h-screen bg-black bg-opacity-20 z-10 top-0 bottom-0 fixed md:hidden ${showLeftBar ? '' : 'hidden'}`} onClick={() => setShowLeftBar(false)} />
    </>
  )
}

const SwitchPatient = () => {
  const familyPatients = useSelector(coreSlice.selectors.selectFamilyPatients)
  const { role } = useSelector(coreSlice.selectors.selectUser)
  const patient = useSelector(coreSlice.selectors.selectPatient)
  const dispatcher = useDispatch()

  const setPatient = React.useCallback(value => {
    const foundPatient = familyPatients.find(({ pk }) => pk === value)
    if (foundPatient) {
      dispatcher(coreSlice.actions.setPatient(foundPatient))
      localStorage.setItem('patient', value)
    }
  }, [dispatcher, familyPatients])

  if (role !== 'family' && role !== 'patient') {
    return null
  }

  const isOnePatient = familyPatients.length === 1 || role === 'patient'

  return (
    <div className='border-y px-3 py-1'>
      <label className='label'>Paciente</label>
      <div className='flex flex-col items-center'>
        {
          isOnePatient
            ? <TextField InputProps={{ style: { fontSize: 12 } }} value={patient?.name} className='w-full' inputProps={{ className: 'text-center' }} sx={{ '& .MuiInputBase-input.Mui-disabled': { WebkitTextFillColor: 'black' } }} disabled />
            :
            <>
              <Select value={patient?.pk ?? ''} className='w-full' inputProps={{ className: 'text-center' }} sx={{ fontSize: 12 }} onChange={({ target: { value } }) => setPatient(value)}>
                {familyPatients.map(({ pk, name }) => <MenuItem sx={{ justifyContent: 'center', fontSize: 12 }} value={pk} key={pk}>{name}</MenuItem>)}
              </Select>
              <FormHelperText>{!patient?.pk ? 'Selecione um paciente' : ''}</FormHelperText>
            </>
        }
      </div>
    </div>
  )
}

export default DashboardLeftBar
