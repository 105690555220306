import React from 'react'
import PatientRecords from '../patient-records'
import PatientReports from '../patient-reports'
import PatientGraphics from '../patient-graphics'
import PatientDetails from '../patient-details'
import VideoStream from '../../video-stream'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardAttendanceSlice, coreSlice, dashboardPatientSlice } from '../../../../reducers'
import { PagesContext } from '../../../../contexts/pages'
import PatientChat from '../patient-chat'
import { useEvent } from '@emerald-works/react-event-bus-client'
import Loading from '../../../loading'
import Attendance from '../../attendance'
import PatientAgenda from '../patient-agenda'

const SinglePatient = () => {
  const { role, pk } = useSelector(coreSlice.selectors.selectUser)
  const {
    singlesPatientPage,
    setSinglePatientPage,
    SinglePatientPages,
    setDashBoardPageInfo
  } = React.useContext(PagesContext)
  const patient = useSelector(dashboardPatientSlice.selectors.selectPatient)
  const inProgressAttendance = useSelector(dashboardAttendanceSlice.selectors.selectInProgressAttendance)
  const [getInProgressAttendance] = useEvent([dashboardAttendanceSlice.eventBus.getInProgressAttendance])
  const dispatcher = useDispatch()

  React.useEffect(() => {
    if (patient.pk) {
      getInProgressAttendance.trigger({ patient: patient.pk })
    }
  }, [patient, singlesPatientPage])

  const isDoctor = React.useMemo(() => role === 'doctor', [role])
  const isOwner = React.useMemo(() => role === 'owner', [role])
  const isSupervisor = React.useMemo(() => patient.supervisors?.find(s => s.pk === pk), [patient, pk])

  const conditionalRender = {
    [SinglePatientPages.PATIENT_DETAILS]: <PatientDetails />,
    [SinglePatientPages.PATIENT_RECORDS]: <PatientRecords />,
    [SinglePatientPages.PATIENT_REPORTS]: <PatientReports />,
    [SinglePatientPages.PATIENT_GRAPHICS]: <PatientGraphics />,
    [SinglePatientPages.PATIENT_ATTENDANCE]: <Attendance />,
    [SinglePatientPages.PATIENT_MESSAGES]: <PatientChat />,
    [SinglePatientPages.PATIENT_AGENDA]: <PatientAgenda />,
  }

  const setPatient = patient => {
    dispatcher(dashboardPatientSlice.actions.setPatient(patient))
  }

  const beginAppointment = () => {
    dispatcher(dashboardAttendanceSlice.actions.setPatient(patient))
    setSinglePatientPage(SinglePatientPages.PATIENT_ATTENDANCE)
  }

  return (
    <section className='font-inter flex flex-col w-full justify-between'>
      <div className='flex flex-col items-center justify-between'>
        <div className='flex title w-full justify-start'>
          <button
            className='outline-none border-none'
            onClick={() => {
              setPatient(null)
              dispatcher(dashboardAttendanceSlice.actions.reset())
              setSinglePatientPage(SinglePatientPages.PATIENT_RECORDS)
            }}
          >
            Pacientes
          </button>
          <span>{'>'}</span>
          <p className='truncate'>{patient.name}</p>
        </div>
        <div className='flex justify-end flex-row w-full'>
          {
            (singlesPatientPage !== SinglePatientPages.PATIENT_ATTENDANCE ? (
              <>
                {isDoctor && <button
                  type='button'
                  className='btn-primary'
                  onClick={() => {
                    setDashBoardPageInfo(state => ({
                      ...state,
                      autoOpenNewEvent: true
                    }))
                    setSinglePatientPage(SinglePatientPages.PATIENT_AGENDA)
                  }}
                >
                  + <span>Agendar Atendimento</span>
                </button>}
                {getInProgressAttendance.isWorking ? <div className='w-32 flex justify-center items-center h-14'><Loading size={5} /></div> :
                  (isDoctor || isOwner) && <button
                    type='button'
                    className='btn-primary'
                    onClick={beginAppointment}
                  >
                    <span>{(isSupervisor || isOwner) ? (inProgressAttendance ? 'Atendimento em andamento' : 'Atendimentos') : 'Atendimentos'}</span>
                  </button>}
              </>
            ) : (
              <button
                type='button'
                className='btn-secondary'
                onClick={() =>
                  setSinglePatientPage(SinglePatientPages.PATIENT_RECORDS)}
              >
                <span>Voltar</span>
              </button>
            ))
          }
        </div>
      </div>
      {singlesPatientPage !== SinglePatientPages.PATIENT_ATTENDANCE &&
        <div className='flex items-center w-full justify-between shadow-sm'>
          <button
            type='button'
            className={`p-4 text-sm border-slate-200 flex-1 border  rounded-r-none rounded-t-lg rounded-b-lg rounded-l-none ${singlesPatientPage === SinglePatientPages.PATIENT_MESSAGES ? 'bg-white' : 'selected-item'}`}
            onClick={() => {
              setPatient(patient)
              setSinglePatientPage(SinglePatientPages.PATIENT_MESSAGES)
            }}
          >
            Mensagens
          </button>
          {/* <button
          type="button"
          className={`p-4 text-sm border-slate-200 flex-1 border  rounded-l-lg rounded-t-lg rounded-b-lg rounded-r-none ${singlesPatientPage === 0 ? 'bg-white' : 'selected-item'
            } `}
          onClick={() => setSinglePatientPage(SinglePatientPages.PATIENT_DETAILS)}
        >
          Detalhes
        </button> */}
          <button
            type='button'
            className={`p-4 text-sm border-slate-200 flex-1 border  rounded-r-none rounded-t-lg rounded-b-lg rounded-l-none ${singlesPatientPage === SinglePatientPages.PATIENT_RECORDS ? 'bg-white' : 'selected-item'}`}
            onClick={() =>
              setSinglePatientPage(SinglePatientPages.PATIENT_RECORDS)}
          >
            Gravações
          </button>
          <button
            type='button'
            className={`p-4 text-sm border-slate-200 flex-1 border  rounded-r-none rounded-t-lg rounded-b-lg rounded-l-none ${singlesPatientPage === SinglePatientPages.PATIENT_AGENDA ? 'bg-white' : 'selected-item'}`}
            onClick={() =>
              setSinglePatientPage(SinglePatientPages.PATIENT_AGENDA)}
          >
            Agenda
          </button>
          {/* <button
          type="button"
          className={`p-4 text-sm border-slate-200 flex-1 border rounded-r-none rounded-t-lg rounded-b-lg rounded-l-none ${singlesPatientPage === 2 ? 'bg-white' : 'selected-item'
            }`}
          onClick={() => setSinglePatientPage(SinglePatientPages.PATIENT_RECORDS)}
        >
          Relatórios
        </button> */}
          {/* <button
          type="button"
          className={`p-4 text-sm border-slate-200 flex-1 border rounded-l-none rounded-t-lg rounded-b-lg rounded-r-lg ${singlesPatientPage === 3 ? 'bg-white' : 'selected-item'
            }`}
          onClick={() => setSinglePatientPage(SinglePatientPages.PATIENT_REPORTS)}
        >
          Gráficos
        </button> */}
        </div>}
      {conditionalRender[singlesPatientPage]}
    </section>
  )
}

export default SinglePatient
