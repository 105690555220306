import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../../utils'

const initialState = {
  supervisor: null,
  searchSupervisors: []
}

export const componentsSelectDoctorSlice = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setSearchSupervisors: (state, { payload }) => {
      state.searchSupervisors = payload
    },
    setSupervisor: (state, { payload }) => {
      state.supervisor = payload
    }
  }
})

export const { reset, setSearchSupervisors, setSupervisor } =
  componentsSelectDoctorSlice.actions

export default componentsSelectDoctorSlice.reducer
