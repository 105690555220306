import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { componentsSelectSupervisorSlice } from '../../reducers'
import Select from 'react-select'

const SelectSupervisor = ({ readOnly, modal, keepState }) => {
  const supervisorsPerPage = 5
  const [search, setSearch] = React.useState('')
  const [searchBy, setSearchBy] = React.useState('name')
  const [isSearching, setIsSearching] = React.useState(false)
  const [searchSupervisors] = useEvent([componentsSelectSupervisorSlice.eventBus.searchSupervisors])
  const supervisorsFromSearch = useSelector(
    componentsSelectSupervisorSlice.selectors.selectSearchSupervisors
  )
  const supervisor = useSelector(componentsSelectSupervisorSlice.selectors.selectSupervisor)

  const dispatcher = useDispatch()

  React.useEffect(() => {
    if (!keepState) {
      dispatcher(componentsSelectSupervisorSlice.actions.reset())
    }
  }, [dispatcher, keepState])

  const searchForSupervisors = React.useCallback(() => {
    if (search.length >= 3) {
      setIsSearching(true)
      searchSupervisors.trigger({ limit: supervisorsPerPage, search, searchBy })
    }
  }, [search, searchBy, searchSupervisors])

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(searchForSupervisors, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [search, searchBy, searchForSupervisors, searchSupervisors])

  const options = supervisorsFromSearch.map(({ pk, name }) => ({
    label: `${name} - ${pk}`,
    id: pk
  }))

  const noOptionsMessage = () => (
    <>
      {searchSupervisors.isWorking
        ? 'Buscando supervisores'
        : !supervisorsFromSearch.length && isSearching
          ? 'Não foram encontrados supervisores'
          : `Digite o ${searchBy === 'email' ? 'e-mail' : 'nome'} do supervisor`}
    </>
  )

  const setSupervisor = ({ id }) => {
    const doctor = supervisorsFromSearch.find(({ pk }) => pk === id)
    dispatcher(componentsSelectSupervisorSlice.actions.setSupervisor(doctor))
    setIsSearching(false)
  }

  return (
    <div className={`flex ${modal ? 'md:w-full' : 'md:w-[48rem]'} w-full flex-col md:flex-row gap-1`}>
      {!readOnly && (
        <div className='flex flex-col pb-2'>
          <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
            Buscar usando
          </label>
          <label className='flex' id='searchBy'>
            <div
              onClick={() => setSearchBy('name')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Nome
            </div>
            <div
              onClick={() => setSearchBy('email')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'email' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Email
            </div>
          </label>
        </div>
      )}
      <Select
        styles={{
          placeholder: (styles) => ({
            ...styles,
            color: readOnly ? styles.color : 'black'
          }),
          input: (styles) => ({
            ...styles,
            paddingTop: 10,
            paddingBottom: 10,
            color: 'black'
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: 'transparent'
          })
        }}
        isDisabled={readOnly}
        className={`${modal ? 'w-64' : 'w-full'} text-xs z-10`}
        value={search}
        onChange={setSupervisor}
        onInputChange={setSearch}
        noOptionsMessage={noOptionsMessage}
        options={options}
        placeholder={
          supervisor?.pk
            ? `${supervisor.name} - ${supervisor.email}`
            : 'Selecione o supervisor'
        }
      />
      {supervisor && (
        <button disabled={readOnly} onClick={() => { dispatcher(componentsSelectSupervisorSlice.actions.setSupervisor(null)) }} className='btn-secondary w-40'>
          Limpar Seleção
        </button>
      )}
    </div >
  )
}
export default SelectSupervisor
