import { createSlice } from '@reduxjs/toolkit'
import name from './name'
import { resetStore } from '../utils'

const initialState = {
  files: [],
  uploadFiles: []
}

export const file = createSlice({
  name,
  initialState,
  reducers: {
    reset: (state) => {
      resetStore(state, initialState)
    },
    setFiles: (state, { payload }) => {
      state.files = payload
    },
    setUploadFiles: (state, { payload }) => {
      state.uploadFiles = payload
    }
  }
})

export const { setFiles, setUploadFiles, reset } = file.actions

export default file.reducer
