import React from 'react'
import SelectDoctor from '../../select-doctor'
import { useDispatch, useSelector } from 'react-redux'
import { componentsSelectDoctorSlice, coreSlice, fileSlice } from '../../../reducers'
import { FileUploader } from 'react-drag-drop-files'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import { useEvent } from '@emerald-works/react-event-bus-client'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loading from '../../loading'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmModal from '../../confirm-modal'

const cutFileName = (fileName) => {
  return fileName.length > 30 ? `${fileName.slice(0, 30)}...` : fileName
}

const Files = () => {
  const [showMyFiles, setShowMyFiles] = React.useState(true)
  const [filesToUpload, setFilesToUpload] = React.useState([])
  const [step, setStep] = React.useState(null)
  const dispatcher = useDispatch()
  const user = useSelector(coreSlice.selectors.selectUser)
  const doctor = useSelector(componentsSelectDoctorSlice.selectors.selectDoctor)
  const uploadFiles = useSelector(fileSlice.selectors.selectUploadFiles)
  const files = useSelector(fileSlice.selectors.selectFiles)
  const [fileToDelete, setFileToDelete] = React.useState(null)
  const [getFiles] = useEvent([fileSlice.eventBus.getFiles])

  const fetchFiles = React.useCallback(() => {
    if (doctor && doctor?.pk) {
      getFiles.trigger({ pk: doctor.pk })
    }
  }, [doctor])

  const [sendFilesToUpload, finishUploadFiles, deleteFile] = useEvent([fileSlice.eventBus.sendFilesToUpload, fileSlice.eventBus.finishUploadFiles, { ...fileSlice.eventBus.deleteFile, onSuccess: fetchFiles }])
  React.useEffect(() => {
    if (showMyFiles) {
      dispatcher(componentsSelectDoctorSlice.actions.setDoctor(user))
    } else {
      dispatcher(componentsSelectDoctorSlice.actions.setDoctor(null))
    }
  }, [showMyFiles])

  const removeFileFromFilesToUpload = React.useCallback((file) => {
    setFilesToUpload(filesToUpload => filesToUpload.filter(f => f !== file))
  }, [])

  const upload = React.useCallback(async () => {
    setStep('Gerando links')
    sendFilesToUpload.trigger({ files: filesToUpload.map(({ type }) => ({ fileType: type })) })
  }, [filesToUpload, doctor])

  React.useEffect(() => {
    const upload = async () => {
      if (uploadFiles?.length > 0 && step === 'Gerando links') {
        setStep('Fazendo upload')
        const success = []
        const fail = []
        for (let i = 0; i < uploadFiles.length; i++) {
          const url = uploadFiles[i].url
          await axios.put(url, filesToUpload[i], {
            headers: {
              "Content-Type": filesToUpload[i].type
            }
          }).then(() => {
            success.push({ fileTytpe: filesToUpload[i].type, sk: uploadFiles[i].sk, fileName: filesToUpload[i].name, fileSize: filesToUpload[i].size })
          }).catch(() => {
            fail.push({ fileTytpe: filesToUpload[i].type, sk: uploadFiles[i].sk, fileName: filesToUpload[i].name, fileSize: filesToUpload[i].size })
          })
        }
        finishUploadFiles.trigger({ files: success })
        setStep('Finalizando')
        if (fail.length > 0) {
          toast.error(`Houve falha no envio de ${fail.length} arquivos`)
        }
        if (success.length > 0) {
          toast.success(`Foram enviados ${success.length} arquivos`)
        }
      }
    }
    upload()
  }, [uploadFiles])

  React.useEffect(() => {
    if (!finishUploadFiles.isWorking && step === 'Finalizando') {
      setFilesToUpload([])
      setStep(null)
      fetchFiles()
    }
  }, [finishUploadFiles.isWorking, step])

  const percent = step === 'Gerando links' ? '10%' : step === 'Fazendo upload' ? '50%' : step === 'Finalizando' ? '90%' : '0%'

  React.useEffect(() => {
    fetchFiles()
  }, [doctor])

  const remove = React.useCallback(() => {
    deleteFile.trigger({ sk: fileToDelete.sk })
    setFileToDelete(null)
    fetchFiles()
  }, [fileToDelete])

  const shouldSelectDoctor = React.useMemo(() => user?.isSupervisor || user?.role === 'owner' || user?.role === 'admin', [user])

  return (
    <section className={`mx-2 ${step !== null ? 'pointer-events-none' : ''}`}>
      <ConfirmModal content={`Tem certeza que deseja deletar o arquivo ${fileToDelete?.fileName}?`} open={fileToDelete !== null} onCancel={() => setFileToDelete(null)} onConfirm={remove} />
      {shouldSelectDoctor && <div className='flex flex-row items-center gap-4'>
        <SelectDoctor readOnly={showMyFiles} />
        <div>
          <label className='label'>Meus Arquivos</label>
          <div className='flex justify-center'>
            <input type='checkbox' className='sr-only peer' checked={showMyFiles} readOnly />
            <div onClick={() => setShowMyFiles(s => !s)} className='relative w-11 h-6 bg-secondary-gray rounded-full peer peer-focus:ring-4 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[""] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-primary-gray after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-blue cursor-pointer' />
          </div>
        </div>
      </div>}
      <div>
        <h1>Enviar arquivos</h1>
        <div className='flex flex-row flex-wrap gap-4'>
          <FileUploader handleChange={(e) => {
            setFilesToUpload(filesToUpload => [...filesToUpload, ...e])
          }} name="file" label='Arraste ou clique para selecionar os arquivos' uploadedLabel='Selecionado com sucesso. Deseja selecionar algum outro?' hoverTitle='Arraste para cá' multiple />
          <button onClick={upload} disabled={filesToUpload?.length < 1} className='btn-primary'>Enviar Arquivos</button>
        </div>
        <div className='flex flex-col w-full'>
          <div class="w-fullrounded-full h-1.5 mb-4">
            <div class="bg-primary-blue h-1.5 rounded-full" style={{ width: percent }}></div>
          </div>
          <div className='flex flex-row items-center justify-center'>
            {step !== null && <p className='text-sm'>{step}</p>}
          </div>
        </div>
        <div className='flex flex-row gap-2 mt-4 flex-wrap'>
          {filesToUpload.map((file, index) => (
            <div key={index} className='flex flex-row items-center gap-2 border-gray-300 border rounded-md'>
              <div className='h-full justify-center items-center flex cursor-pointer hover:bg-gray-300' onClick={() => { removeFileFromFilesToUpload(file) }}>
                <CloseIcon />
              </div>
              <div className='p-2 flex flex-row justify-center'>
                <div className='w-8 h-8 bg-primary-gray rounded-full flex items-center justify-center'>
                  <AttachFileIcon />
                </div>
                <div>
                  <label className='label'>{cutFileName(file.name)}</label>
                  <p className='text-sm text-gray-600'>{(file.size / (1000 * 1000)).toFixed(2)} MB</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h1>Arquivos</h1>
        <div className='flex justify-center flex-wrap gap-4 min-h-20'>
          {getFiles.isWorking ? <Loading /> : files.length === 0 ? 'Não há arquivos' : files.map((file, index) => (
            <div key={index} className='flex flex-row items-center gap-2 border-gray-300 border rounded-md cursor-pointer'>
              <div className='p-2 flex flex-row justify-center cursor-pointer' onClick={() => { window.open(file.url, '_blank') }}>
                <div className='w-8 h-8 bg-primary-gray rounded-full flex items-center justify-center cursor-pointer'>
                  <AttachFileIcon />
                </div>
                <div className='cursor-pointer'>
                  <label className='label cursor-pointer'>{cutFileName(file.fileName)}</label>
                  <p className='text-sm text-gray-600 cursor-pointer'>{(file.fileSize / (1000 * 1000)).toFixed(2)} MB</p>
                </div>
              </div>
              {user?.pk === doctor?.pk && <div className='h-full justify-center items-center flex cursor-pointer hover:bg-gray-300 text-gray-500' onClick={() => { setFileToDelete(file) }}>
                <DeleteIcon />
              </div>}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default Files